import React from 'react'

export default ({testimonials}) => (
  <section className="home__testimonials testimonials">
    <div className="container">
      <div className="home__testimonials__carousel js-home-testimonials slick-slider">
        { testimonials.map((testimonial, key) => (
        <div key={key}>
          <div className="home__testimonials__carousel__item">
              <div key={key}>
                <div className="home__testimonials__carousel__item__content">
                  <p dangerouslySetInnerHTML={{__html: testimonial.content}} />
                </div>
                <p className="home__testimonials__carousel__item__author">
                  {testimonial.author}
                </p>
              </div>
          </div>
        </div>
        ))}
      </div>
    </div>
  </section>
)
