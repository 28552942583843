import React from 'react'
import {Link} from 'gatsby'
import html2plaintext from 'html2plaintext'

export default ({articles}) => {
  articles = articles.map(({childMarkdownRemark}) => ({
    frontmatter: childMarkdownRemark.frontmatter,
    html: childMarkdownRemark.html,
  }));

  const categories = {
    'Opieka w ciąży': [],
    'Ginekologia': [],
    'Badania USG w ciąży': [],
    'Tiliamed': []
  };

  articles.map((article) => {
    for(let cat of article.frontmatter.category) {
      categories[cat].push(article)
    }
  })

  for(const category of Object.keys(categories)) {
    categories[category].sort(function (a, b) {
      return new Date(b.frontmatter.date) - new Date(a.frontmatter.date)
    })
  }

  return (
    <section className="home__articles">
      <div className="container">

        <div className="row">
          <div className="col-xs-12 col-sm-4">
            <div className="home__articles__box home__articles__box--0">
              <div className="title">
                <Link to={"/edukacja/"}>
                  Opieka w ciąży ({categories['Opieka w ciąży'].length})
                </Link>
              </div>

              <div className="article" data-mh="home-article-box">

                <p className="article__title">
                  {categories['Opieka w ciąży'][0].frontmatter.title}
                </p>

                <div className="article__content" dangerouslySetInnerHTML={{
                  __html: html2plaintext(categories['Opieka w ciąży'][0].html).substring(0, 300) + '...'
                }} />

                <div className="article__button">
                  <Link to={categories['Opieka w ciąży'][0].frontmatter.slug}
                     className="btn btn-white">
                    czytaj artykuł
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4">
            <div className="home__articles__box home__articles__box--1">
              <div className="title">
                <Link to={"/edukacja/"}>
                  Ginekologia ({categories['Ginekologia'].length})
                </Link>
              </div>

              <div className="article" data-mh="home-article-box">

                <p className="article__title">
                  {categories['Ginekologia'][0].frontmatter.title}
                </p>

                <div className="article__content" dangerouslySetInnerHTML={{
                  __html: html2plaintext(categories['Ginekologia'][0].html).substring(0, 300) + '...'
                }} />

                <div className="article__button">
                  <Link to={categories['Ginekologia'][0].frontmatter.slug}
                     className="btn btn-white">
                    czytaj artykuł
                  </Link>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xs-12 col-sm-4">
            <div className="home__articles__box home__articles__box--2">
              <div className="title">
                <Link to={"/edukacja/"}>
                  Badania USG w ciąży ({categories['Badania USG w ciąży'].length})
                </Link>
              </div>

              <div className="article" data-mh="home-article-box">

                <p className="article__title">
                  {categories['Badania USG w ciąży'][0].frontmatter.title}
                </p>

                <div className="article__content" dangerouslySetInnerHTML={{__html:
                    html2plaintext(categories['Badania USG w ciąży'][0].html).substring(0, 300) + '...'
                }} />

                <div className="article__button">
                  <Link to={categories['Badania USG w ciąży'][0].frontmatter.slug}
                     className="btn btn-white">
                    czytaj artykuł
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="home__articles__more hidden-xs">
          <Link to={"/edukacja/"}>
            <svg width="43" height="16" viewBox="0 0 43 16" xmlns="http://www.w3.org/2000/svg"><title>czytaj dalej
              arrow</title>
              <path
                d="M42.584 6.803c-.015-.015-.01-.04-.025-.056L36.392.371a1.207 1.207 0 0 0-1.739 0 1.3 1.3 0 0 0 0 1.797l4.135 4.275H1.228C.55 6.443 0 7.01 0 7.712c0 .7.55 1.27 1.228 1.27h37.555l-4.13 4.269a1.293 1.293 0 0 0 0 1.792c.241.249.555.376.87.376.314 0 .628-.127.869-.376l6.167-6.377c.256-.274.408-.594.408-.954s-.152-.675-.383-.909z"
                fill="#9B9B9B"/>
            </svg>
            <br/>
            czytaj więcej
          </Link>
        </div>
      </div>
    </section>
  )
}
