import React from "react"

import Layout from "@layout/layout"
import {graphql, Link, withPrefix} from "gatsby"
import Helmet from "react-helmet";
import BodyClassName from 'react-body-classname';

import {meet, testimonials} from '@content/homepage.yaml'
import NewClinic from "@homepage/new-clinic";
import Meet from "@homepage/meet";
import Calendar from "@homepage/calendar";
import Testimonials from "@homepage/testimonials";
import Articles from "@homepage/articles";
import Hero from "@homepage/hero";
import TableOfContents from "@homepage/table-of-contents";
import SEO from "@layout/seo";

export default ({data}) => (
  <BodyClassName className="home">
    <Layout>
      <SEO
        title={'Poradnia ginekologiczna - Klinika Tiliamed Warszawa'}
        description={'Tiliamed to klinika położniczo-ginekologiczna mieszcząca się w Wołominie pod Warszawą. Specjalizujemy się w diagnostyce i leczeniu chorób narządu rodnego.'}
      />
      <Helmet>
        <script type="application/ld+json">
        {
          `
          {"@context":"http:\\/\\/schema.org","@type":"WebSite","@id":"#website","url":"https:\\/\\/tiliamed.pl\\/","name":"Tiliamed"}
          `
        }
        </script>
      </Helmet>
      <div className="content">
        <main>
          <Hero/>
          <Meet meet={meet}/>
          <NewClinic />
          <Calendar/>
          <Testimonials testimonials={testimonials}/>
          <Articles articles={data.blog.edges.map(({node}) => node)}/>
        </main>
      </div>
      <TableOfContents/>
    </Layout>
  </BodyClassName>
)

export const pageQuery = graphql`
    query HomepageBlog {
        blog: allFile(filter: {sourceInstanceName: {eq: "blog"}})  {
            edges {
                node {
                    sourceInstanceName
                    childMarkdownRemark {
                      html
                      frontmatter {
                        title
                        slug
                        category
                        date
                      }
                    }
                }
            }
        }
    }`
