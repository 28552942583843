import React, {useState} from 'react';
import {StaticImage} from "gatsby-plugin-image"
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import {withPrefix} from "gatsby";
import FsLightbox from 'fslightbox-react';

const NewClinic = () => {
    const [lightboxController, setLightboxController] = useState({
        toggler: false,
        slide: 1
    });

    function setSlide(number) {
        setLightboxController({
            toggler: !lightboxController.toggler,
            slide: number
        });
    }

    return (
        <div className="container new-clinic-heading">
            <FsLightbox
                toggler={lightboxController.toggler}
                sources={[
                    withPrefix('images/clinic/1.png'),
                    withPrefix('images/clinic/2.png'),
                    withPrefix('images/clinic/3.png'),
                    withPrefix('images/clinic/4.png'),
                    withPrefix('images/clinic/5.png'),
                    withPrefix('images/clinic/6.png'),
                ]}
                slide={lightboxController.slide}
            />
            <div className="row">
                <div className="text-center">
                    <h2 className={'home__meet__heading'} style={{'maxWidth': '450px', 'margin': '0 auto'}}>
                        Zobacz naszą klinikę po remoncie!
                    </h2>
                </div>
            </div>
            <div className="clinic-slider-desktop">
                <div className="clinic-slider-desktop__left">
                    <span onClick={() => setSlide(1)} style={{cursor: 'pointer'}}>
                        <StaticImage src="../../images/clinic/1.png" alt="zdjęcie kliniki 1" quality={80}
                                     formats={['auto', 'webp', 'avif']}/>
                    </span>
                    <span onClick={() => setSlide(4)} style={{cursor: 'pointer'}}>
                        <StaticImage src="../../images/clinic/4.png" alt="zdjęcie kliniki 4" quality={80}
                                     formats={['auto', 'webp', 'avif']}/>
                    </span>
                </div>
                <div className="clinic-slider-desktop__right">
                    <div className="clinic-slider-desktop__right__top">
                        <span onClick={() => setSlide(2)} style={{cursor: 'pointer'}}>
                            <StaticImage src="../../images/clinic/2.png" alt="zdjęcie kliniki 2" quality={80}
                                         formats={['auto', 'webp', 'avif']}/>
                        </span>
                        <span onClick={() => setSlide(3)} style={{cursor: 'pointer'}}>
                            <StaticImage src="../../images/clinic/3.png" alt="zdjęcie kliniki 3" quality={80}
                                         formats={['auto', 'webp', 'avif']}/>
                        </span>
                    </div>
                    <div className="clinic-slider-desktop__right__bottom">
                        <span onClick={() => setSlide(5)} style={{cursor: 'pointer'}}>
                            <StaticImage src="../../images/clinic/5.png" alt="zdjęcie kliniki 5" quality={80}
                                         formats={['auto', 'webp', 'avif']}/>
                        </span>
                        <span onClick={() => setSlide(6)} style={{cursor: 'pointer'}}>
                            <StaticImage src="../../images/clinic/6.png" alt="zdjęcie kliniki 6" quality={80}
                                         formats={['auto', 'webp', 'avif']}/>
                        </span>
                    </div>
                </div>
            </div>
            <div className="clinic-slider-mobile">
                <Swiper
                    spaceBetween={16}
                    slidesPerView={2}
                    loop={true}
                >
                    <SwiperSlide className={'x105px'}>
                        <span onClick={() => setSlide(1)}>
                            <StaticImage src="../../images/clinic/1.png" alt="zdjęcie kliniki 1" quality={80}
                                         formats={['auto', 'webp', 'avif']}/>
                        </span>
                    </SwiperSlide>
                    <SwiperSlide className={'x234px'}>
                        <span onClick={() => setSlide(2)}>
                        <StaticImage src="../../images/clinic/2.png" alt="zdjęcie kliniki 2" quality={80}
                                     formats={['auto', 'webp', 'avif']}/>
                        </span>
                    </SwiperSlide>
                    <SwiperSlide className={'x105px'}>
                        <span onClick={() => setSlide(3)}>
                        <StaticImage src="../../images/clinic/3.png" alt="zdjęcie kliniki 3" quality={80}
                                     formats={['auto', 'webp', 'avif']}/>
                        </span>
                    </SwiperSlide>
                    <SwiperSlide className={'x234px'}>
                        <span onClick={() => setSlide(4)}>
                        <StaticImage src="../../images/clinic/4.png" alt="zdjęcie kliniki 4" quality={80}
                                     formats={['auto', 'webp', 'avif']}/>
                        </span>
                    </SwiperSlide>
                    <SwiperSlide className={'x234px'}>
                        <span onClick={() => setSlide(5)}>
                        <StaticImage src="../../images/clinic/5.png" alt="zdjęcie kliniki 5" quality={80}
                                     formats={['auto', 'webp', 'avif']}/>
                        </span>
                    </SwiperSlide>
                    <SwiperSlide className={'x234px'}>
                        <span onClick={() => setSlide(6)}>
                        <StaticImage src="../../images/clinic/6.png" alt="zdjęcie kliniki 6" quality={80}
                                     formats={['auto', 'webp', 'avif']}/>
                        </span>
                    </SwiperSlide>
                </Swiper>
            </div>
        </div>
    );
}

export default NewClinic;
