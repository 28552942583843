import {Link, withPrefix} from "gatsby";
import React from "react";

export default ({meet}) => (
  <section className="home__meet">
    <div className="container-1050">
      <div className="js-meet-carousel slick-slider">
        {meet.map((slide, key) => (
          <div className="row" key={key}>
            <div className="col-sm-12 col-md-6 col-md-push-6">
              <h2 className="home__meet__heading" dangerouslySetInnerHTML={{__html: slide.heading}}/>
              <div className="home__meet__content">
                <p dangerouslySetInnerHTML={{__html: slide.content}}/>
              </div>
              <div className="home__meet__button hidden-xs hidden-sm">
                <Link className="btn btn-primary" to={slide.link}>
                  {slide.button_text}
                </Link>
              </div>
            </div>
            <div className="col-sm-12 col-md-6 col-md-pull-6">
              <div className="home__meet__image">
                <img src={withPrefix(slide.image.url)}
                     className="img-responsive center-block"
                     alt={slide.image.alt}/>
              </div>
              <div className="home__meet__button visible-xs visible-sm">
                <a className="btn btn-primary">
                  {slide.button_text}
                </a>
              </div>
              <div className="home__meet__button--placeholder visible-xs visible-sm">&nbsp;</div>
            </div>
          </div>
        ))}
      </div>
    </div>
  </section>
)
